// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const AuthContext = createContext();


export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const token = localStorage.getItem('token');
    return token ? { token } : null;
  });
  const [userData, setUserData] = useState(null); // State to store user data
  const [nftData, setNftData] = useState(null); // State to store NFT data
  const [loginError, setLoginError] = useState(null); // State to store login error
  const [registerError, setRegisterError] = useState(null); // State to store registration error
  const history = useNavigate(); // Use the react-router-dom hook to navigate
  const [allNfts,setAllnfts] = useState([]);
  const [defUrllink,setDefUrlLink] = useState('https://mintera.art/snooart/');
  const navigate = useNavigate();


  const login = async (username, password) => {
    try {
     
      const response = await axiosInstance.post('main.php?xAction=loginUser&username=' + username + '&password=' + password);

      const token = response.data.token;
      //console.log(token);
      if (!token) {
        setLoginError('No token received'); // Set login error if no token received
       
        toast.error("Invalid username or password", {
          position: "top-right"
        });
        return;
      } else {
        localStorage.setItem('token', token);
        setCurrentUser({ token });
        console.log('logged user '+ localStorage.getItem('token'))
        
        toast.success("You logged in successfully.", {
          position: "top-center"
        });

        // Fetch user data after successful login
        await fetchUserData();

        // Redirect to dashboard after successful login
        history('/dashboard'); // Redirect to the dashboard upon success
      }


    } catch (error) {
      setLoginError('Login failed. Please try again.'); // Set login error on API failure
      throw error; 
    }
  };

  const otp = async (userid, otp) => {
    try {
     
      const response = await axiosInstance.post('main.php?xAction=verifyotp&userid=' + userid + '&otp=' + otp);

      const token = response.data.token;
      //console.log(token);
      if (!token) {
        setLoginError('No token received'); // Set login error if no token received
       
        toast.error(response.data.message, {
          position: "top-right"
        });
        return;
      } else {
        localStorage.setItem('token', token);
        setCurrentUser({ token });
        console.log('logged user '+ localStorage.getItem('token'))
        
        toast.success("Welcome back!", {
          position: "top-center"
        });

        // Fetch user data after successful login
        await fetchUserData();

        // Redirect to dashboard after successful login
        history('/dashboard'); // Redirect to the dashboard upon success
      }


    } catch (error) {
     // setLoginError('Login failed. Please try again.'); // Set login error on API failure
      throw error; 
    }
  }

  const register = async (username, email, password) => {
    try {
    
      const response = await axiosInstance.post('main.php?xAction=registerUser&username=' + username + '&email=' + email + '&password=' + password);


      const token = response.data.token;
      //console.log(token);
      if (!token) {
        if (response.data.status === 'USED_MAIL') {
          setRegisterError('Used Mail'); // Set login error if no token received
         
          toast.error("Email/Username already exist", {
            position: "top-right"
          });
        }else{
        setRegisterError('No token received'); // Set login error if no token received
       
        toast.error("Server Error", {
          position: "top-right"
        });
      }
        return;
      } else {
        localStorage.setItem('token', token);
        setCurrentUser({ token });
        //console.log(token);
        
        toast.success("You Registered successfully.", {
          position: "top-center"
        });
        // Fetch user data after successful login
        await fetchUserData();

        // Redirect to dashboard after successful login
        history('/dashboard'); // Redirect to the dashboard upon success
      }
    } catch (error) {
      //console.error('Registration failed:', error);
      setRegisterError('Registration failed. Please try again.'); // Set generic registration error message
    }
  };
  const resetpassword = async (otp, email, password) => {
    try {
    
      const response = await axiosInstance.post('main.php?xAction=resetpassword&otp=' + otp + '&email=' + email + '&password=' + password);

      if(response.data.status){
        toast.success(response.data.message, {
          position: "top-right"
        });
      }else{
        toast.error(response.data.message, {
          position: "top-right"
        });
      }
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
      toast.error(error, {
        position: "top-right"
      });
    }
  };

  const logout = () => {
    toast.warning("Logging out...", {
      position: "top-center"
    });
    localStorage.removeItem('token');
    setCurrentUser(null);
    setUserData(null); // Clear user data on logout
   // Redirect after 2 seconds
   setTimeout(() => {
    history('/');
}, 3000);
  };

  const buyArt = async (art_id, value) => {
    try {  
      // Make a POST request to the server to buy art
      const response = await axiosInstance.post(`main.php?xAction=buyArt&art_id=${art_id}&value=${value}`);
  
      // Check if the response is successful
      if (response.data.message === 'Bidding successful!') {
        // Handle successful response
       
        toast.success("Art purchased successfully.", {
          position: "top-right"
        });
        // You may want to perform additional actions upon successful purchase
        return response.data; // Return the response data
      } else {
        // Handle other cases, such as server errors
        
        toast.warning(response.data.message, {
          position: "top-right"
        });
        return null; // Return null in case of error
      }
    } catch (error) {
      // Handle errors
      //console.error('Art purchase failed:', error);
     
      toast.error('Sign in to purchase art.', {
        position: "top-right"
      });
      return null; // Return null in case of error
    }
  };
 
  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get('main.php?xAction=getUserData');
      setUserData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        setCurrentUser(null);
        //history('/login');
        //alert('Expired token');
        //console.log('Got 401 Unauthorized error'); 
      } else {
        localStorage.removeItem('token');
        setCurrentUser(null);
       // history('/login');
        //alert('Expired token');
        //console.error('Error fetching user data:', error);  
      }
    }
  };
  const fetchAllnfts = async () => {
    try {
      const response = await axiosInstance.get('main.php?xAction=getAllnft');
      setAllnfts(response.data); // Store user data in state
      //console.log(response.data);
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
    }
  };
  const nftdata = async (id) => {
    try {
      const response = await axiosInstance.get('main.php?xAction=nftdata&id='+id);
      console.log(response.data);
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
    }
  };
  const sendotp  = async (id) => {
    try {
      const response = await axiosInstance.get('main.php?xAction=sendotp&id='+id);
      //setAllnfts(response.data); // Store user data in state
      //console.log(response.data);
      if(response.data.status === 'true'){
        toast.success('OTP sent to mail.', {
          position: "top-right"
        });
      }else{
        toast.error(response.data.message, {
          position: "top-right"
        });
      }
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
      toast.error(error, {
        position: "top-right"
      });
    }
  };
  const checkUser = async () => {
    try {
      const response = await axiosInstance.get('main.php?xAction=checkLogin');
      const data = response.data;
      return response.data;
      
    } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          setCurrentUser(null);
          //history('/login');
          //alert('Expired token');
          //console.log('Got 401 Unauthorized error'); 
        } else {
          localStorage.removeItem('token');
          setCurrentUser(null);
         // history('/login');
          //alert('Expired token');
          //console.error('Error fetching user data:', error);  
        }
    }
  };


  const fetchNftData = async (uid, category, offset, limit) => {
    try {
      const response = await axiosInstance.get('/main.php?xAction=listNFTs&'+'id='+uid+'&category='+category+'&offset='+offset+'&limit='+limit);
      setNftData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      //console.error('Error fetching NFT data:', error);
    }
  };
  const fetchExhibition = async (uid, offset, limit) => {
    try {
      const response = await axiosInstance.get('/main.php?xAction=listexhibition&'+'id='+uid+'&offset='+offset+'&limit='+limit);
      //setExhibitionData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      console.error('Error fetching NFT data:', error);
    }
  };
  const fetchTrnx = async ( offset, limit) => {
    try {
      const response = await axiosInstance.get('/main.php?xAction=listtrnx&'+'&offset='+offset+'&limit='+limit);
      setNftData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      //console.error('Error fetching NFT data:', error);
    }
  };

  const fetchSales = async ( offset, limit) => {
    try {
      const response = await axiosInstance.get('/main.php?xAction=listsales&'+'&offset='+offset+'&limit='+limit);
      setNftData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      //console.error('Error fetching NFT data:', error);
    }
  };

  const fetchProfile = async (id) => {
    try {
      const response = await axiosInstance.get('main.php?xAction=getUserProfile&id='+id);
      //setUserData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
      toast.error('Unable to fetch profile. Please try again.', {
        position: "top-right"
      });
    }
  };
  const listusers = async () => {
    try {
      const response = await axiosInstance.get('main.php?xAction=listusers');
      //setUserData(response.data); // Store user data in state
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
      toast.error('Unable to users. Please try again.', {
        position: "top-right"
      });
    }
  };
  const pushmarket = async (id,event) => {
    try {
      const response = await axiosInstance.get('main.php?xAction=pushmarket&id='+id+'&event='+event);
      if(response.data.status && response.data.message === 'true'){
      toast.success('Pushed to the market succesfully', {
        position: "top-right"
      });
    }else if(response.data.status && response.data.message === 'false'){
      toast.warning('Removed from the market succesfully', {
        position: "top-right"
      });
    }
      return response.data;
    } catch (error) {
      //console.error('Error fetching user data:', error);
      toast.error('Unable to fetch profile. Please try again.', {
        position: "top-right"
      });
    }
  }

  useEffect(() => {
    // Check if the user is already logged in (e.g., token exists)
    fetchAllnfts();
    //checkUser();
    console.log('checking user '+ localStorage.getItem('token'))
   

    // Check if the user is already logged in (e.g., token exists)
    const token = localStorage.getItem('token');
    if (token) {
      //checkUser();

      setCurrentUser({ token });

      // Fetch user data when the component mounts if the user is already logged in
      fetchUserData();
    }
  }, []);

  const value = {
    currentUser,
    fetchExhibition,
    listusers,
    defUrllink,
    pushmarket,
    fetchSales,
    otp,  
    fetchTrnx,
    sendotp,
    allNfts,
    fetchAllnfts,
    userData,
    login,
    nftdata,
    register,
    resetpassword,
    logout,
    loginError,
    fetchNftData,
    fetchUserData,
    fetchProfile,
    buyArt,
    registerError, // Include login error state in the context
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
