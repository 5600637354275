// api/axiosInstance.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://mintera.art/snooart/', // Set your API base URL
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to include the bearer token
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
